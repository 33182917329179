import { deleteDataById, getData, getExcel, getPaginatedData, insertData, patchData } from './commonService';

export default class EmployeeBonusService {

    #defaultUrl = '/api/EmployeeBonus';
    #defaultResourceName = 'Bonus';

    async insertEmployeeBonus(dataItem) {
        const createData = {
            "projectEmployeeId": dataItem.projectEmployeeId,
            "employeeId": dataItem.employeeId,
            "projectId": dataItem.projectId,
            "bonusPrice": dataItem.bonusPrice,
            "year": dataItem.year,
            "month": dataItem.month,
            "comment": dataItem.comment,
            "managerUserId": dataItem.managerUserId,
            "bonusDate": dataItem.bonusDate,
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async updateBonus(id, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "bonusPrice", "value": dataItem.bonusPrice},
            {"op": "replace", "path": "year", "value": dataItem.year},
            {"op": "replace", "path": "month", "value": dataItem.month},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "isApproved", "value": dataItem.isApproved},
            {"op": "replace", "path": "comment", "value": dataItem.comment},
            {"op": "replace", "path": "managerUserId", "value": dataItem.managerUserId},
            {"op": "replace", "path": "bonusDate", "value": dataItem.bonusDate},
        ];

        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);

    }

    async updateBonusApprovedStatus(id, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isApproved", "value": newStatus}
        ];

        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);

    }


    async deleteEmployeeBonus(id) {
        return await deleteDataById(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async getEmployeeBonuses(projectEmployeeId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectEmployeeId=' + projectEmployeeId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getEmployeesAllBonuses(projectEmployeeId) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectEmployeeId=' + projectEmployeeId , this.#defaultResourceName);
    }

    async getAllBonuses(startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getAllBonusReport(startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectBonusReport(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectBonuses(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectBonusReportForAccounting(projectId, startDate, endDate) {
        return await getData(this.#defaultUrl + '/report?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectBonusForAccounting(projectId, startDate, endDate) {
        return await getPaginatedData(this.#defaultUrl + '/query?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getAllBonusExcel(startDate, endDate) {
        return await getExcel(this.#defaultUrl + '/Excel?startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }

    async getProjectBonusesExcel(projectId, startDate, endDate) {
        return await getExcel(this.#defaultUrl + '/Excel?projectId=' + projectId + '&startDate=' + startDate.valueOf() + '&endDate=' + endDate.valueOf() , this.#defaultResourceName);
    }
}