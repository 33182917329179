<template>
    <div class='grid'>
        <AdminToast></AdminToast>
        <ConfirmDialog></ConfirmDialog>
        <BonusReport :report='report' />

        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>
                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Prim Listesi</h5>
                    </div>
                    <div class='col-12 lg:col-4 text-right'>
                    </div>
                </div>

                <Toolbar class='mb-2'>
                    <template v-slot:start>
                        <div>
                            <Calendar id='selectedMonth' view="month" dateFormat="MM - yy" :showIcon='true' :showButtonBar='true' v-model='selectedDate' @date-select='selectedDateChanged'></Calendar>
                        </div>
                        <div class='ml-3'>
                            <Dropdown :model-value='selectedProject' :options='projectList' option-value='id' option-label='name' @change='projectChange' ></Dropdown>
                        </div>
                    </template>

                    <template v-slot:end>
                        <Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
                            <h3>Prim Listesi</h3>
                            <p class="text-lg">

                            </p>
                        </Sidebar>

                        <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2'  />
                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success' @click='exportExcel'></Button>
                    </template>
                </Toolbar>



                <DataTable :value='bonusList' class='p-datatable-gridlines' :rows='10' dataKey='id' :rowHover='true' :paginator='true' :loading='loadingIndicator' responsiveLayout='scroll'>

                    <template #empty>
                        Kayıtlı Prim bulunamadı.
                    </template>

                    <template #loading>
                        Primler yükleniyor. Lütfen bekleyin.
                    </template>
                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                        <template #body='{data}'>
                            <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                        </template>
                    </Column>

                    <Column field='project.name' header='Proje' style='width:16rem'>
                    </Column>

                    <Column field='employee.name' header='Ad' style='width:12rem'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.employee.name }}
                            </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad' style='width:12rem'>
                        <template #body='{data}'>
                            <a @click='edit(data)' class="cursor-pointer">
                                {{ data.employee.surName }}
                            </a>
                        </template>
                    </Column>

                    <Column field='projectEmployee.department' header='Departman' style='width:9rem'>
                    </Column>
                    <Column field='startDate' header='Prim Tarihi' style='width:10rem'>
                        <template #body='slotProps'>
                            {{ formatDate(slotProps.data.bonusDate) }}
                        </template>
                    </Column>

                    <Column field='totalPrice' header='Prim Tutarı' style='width:10rem'>
                        <template #body='{data}'>
                            {{ formatPrice(data.bonusPrice) }} &#8378;
                        </template>
                    </Column>
                    <Column field='comment' header='Açıklama'>
                    </Column>

                    <Column field='verified' header='Onay Durumu' dataType='boolean' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isApproved, 'text-pink-500 pi-times-circle': !data.isApproved}" @click='changeApproveStatus(data.id, ! data.isApproved, data.projectEmployeeId)'></i>
                        </template>
                    </Column>

                    <Column header='Görüntüle' bodyClass='text-center' style='width:6.5rem'>
                        <template #body='slotProps'>
                            <Button icon='pi pi-search' class='p-button-rounded p-button-primary' @click='edit(slotProps.data)' />
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>

</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import { showSuccessMessage } from '../../components/shared/toast/toastFunction';
import BonusReport from '../../components/shared/BonusReport';
import { checkEmployeeSalaryCalculated } from '../common/commonFunctions';
import AdminToast from '../../components/shared/toast/adminToast';
import { checkUserRole, getProjects } from '../common/commonConstantFunctions';
import EmployeeBonusService from '@/services/employeeBonusService';

export default {
    components: { AdminToast, BonusReport },
    data() {
        return {
            selectedProject:-1,
            projectList:[],
            selectedDate:null,
            bonusList: [],
            filters: null,
            loadingIndicator: true,
            idFrozen: false,
            products: null,
            expandedRows: [],
            report: {
                totalPrice:0,
                paidTotal:0,
                pendingTotal:0,
                totalItem:0
            },
            visibleLeft:false,
        };
    },
    _employeeBonusService: null,
    created() {
        checkUserRole(this, "Admin");
        this.projectList = getProjects();
        this.selectedDate = moment().toDate();
        this._employeeBonusService = new EmployeeBonusService();
        this.initFilters();
        this.loadingIndicator = true;
    },
    async mounted() {
        await this.getEmployeeBonusList();
    },
    methods: {
        async changeApproveStatus(id, newStatus, projectEmployeeId) {
            if(await checkEmployeeSalaryCalculated(this, this.selectedDate, projectEmployeeId))
            {
                return;
            }
            this.$confirm.require({
                message: 'Prim İsteğini Onaylamak İstediğinize Emin Misiniz?',
                header: 'Onay',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Evet',
                rejectLabel: 'Hayır',
                accept: async () => {
                    let response = await this._employeeBonusService.updateBonusApprovedStatus(id, newStatus);
                    if (response.isSuccess) {
                        showSuccessMessage(this, "Prim Statüsü Değişti");
                    }
                    await this.getEmployeeBonusList();
                }
            });


        },
        async projectChange(event) {
            this.selectedProject = event.value;
            await this.getEmployeeBonusList();
        },

        async exportExcel() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let excelExports = await this._employeeBonusService.getAllBonusExcel(startDate, endDate);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Primler " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },
        async selectedDateChanged() {
            await this.getEmployeeBonusList();
        },
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        clearFilter() {
            this.initFilters();
        },
        expandAll() {
            this.expandedRows = this.products.filter(p => p.id);
        },
        collapseAll() {
            this.expandedRows = null;
        },
        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        edit(item) {
            this.$router.push({ name: 'bonusProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.projectEmployeeId } });
        },

        async getEmployeeBonusList() {
            this.loadingIndicator = true;
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let bonusResponse = await this._employeeBonusService.getProjectBonuses(this.selectedProject, startDate, endDate);
            if (bonusResponse.isSuccess) {
                this.bonusList = bonusResponse.data;
            }
            await this.getEmployeeBonusReport();
            this.loadingIndicator = false;
        },

        async getEmployeeBonusReport() {
            let startDate = moment(this.selectedDate).startOf('month').toDate();
            let endDate = moment(this.selectedDate).endOf('month').toDate();
            let response = await this._employeeBonusService.getProjectBonusReportForAccounting(this.selectedProject, startDate, endDate);
            if (response.isSuccess) {
                this.report = response.data;
            }
        }
    },
};
</script>
